// import {Button, Center, Heading, Text, VStack} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

function PaymentSuccess() {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate('/');
  };
  return <div></div>;
}

export default PaymentSuccess;
