import apiClient from './clientFactory';

import type { GroupedAppointment } from '../resources/MyProfile/AvailableAppointments';

export class AppointmentApi {
  private _client = apiClient;
  private APPOINTMENT_API = '/appointment';
  private CREATE_APPOINTMENT_API = '/createAppointment';

  public async bookAppointment(
    date: Date,
    time: string | null,
    consultant: string
  ) {
    return await this._client.post(`${this.APPOINTMENT_API}/book`, {
      date: date,
      time: time,
      consultant: consultant,
    });
  }

  public async createAppointments(addAppointment: any) {
    return await this._client.post(this.CREATE_APPOINTMENT_API, addAppointment);
  }

  public async getAllAppointments() {
    return await this._client.get(`${this.APPOINTMENT_API}/getAllAvailable`);
  }

  public async getAllAppointmentsGrouped(): Promise<GroupedAppointment[]> {
    return await this._client.get(
      `${this.APPOINTMENT_API}/getAllAppointmentsGrouped`
    );
  }
}

export const appointmentApi = new AppointmentApi();
