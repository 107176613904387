import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useSignIn } from 'react-auth-kit';
import { useDispatch } from 'react-redux';

import { setLoggedInUserDetails } from '../../features/userDetailsSlice';
import { authApi } from '../API/AuthApi';
import { forSignUp } from '../assets';
import { google } from '../assets';
import { useNotification } from '../context/NotificationProvider';

const StyledTextField = (props: any) => (
  <TextField
    variant="filled"
    fullWidth
    margin="normal"
    {...props}
    sx={{ backgroundColor: 'white' }}
  />
);

const SignIn = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { showNotification } = useNotification();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate('/signup');
  };
  const submit = () => {
    authApi
      .login(username, password)
      .then((response) => {
        signIn({
          token: response.accessToken,
          expiresIn: 3600,
          tokenType: 'Bearer',
          authState: { username: username, role: 'CONSULTANT' },
        });
        localStorage.setItem('loggedInUser', JSON.stringify(response));
        dispatch(setLoggedInUserDetails(response));
        navigate('/');
      })
      .catch((error) => {
        showNotification(true, 'success', 'SignUp Error', error.response.data);
      });
  };

  const submitUserDataFromGoogleAccount = (userData: any) => {
    authApi
      .googleLogin(userData['email'], String(userData['sub']))
      .then((response) => {
        signIn({
          token: response.accessToken,
          expiresIn: 3600,
          tokenType: 'Bearer',
          authState: { username: username },
        });
        localStorage.setItem('loggedInUser', JSON.stringify(response));
        dispatch(setLoggedInUserDetails(response));
        navigate('/');
      })
      .catch((error) => {
        showNotification(true, 'success', 'Login Error', error.response.data);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => {
          submitUserDataFromGoogleAccount(res.data);
        });
    },
    onError: (error) => console.log('Google login Failed:', error),
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="80vh"
      sx={{
        backgroundImage: `linear-gradient(rgba(72, 156, 242, 0.8), rgba(0, 0, 0, 0.8)), url(${forSignUp})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
      }}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '30px',
            lineHeight: '66.8px',
            width: '250px',
            color: 'white',
          }}
        >
          Sign In
        </Typography>
        <StyledTextField
          label="Email"
          onChange={(e: any) => setUsername(e.target.value)}
        />
        <StyledTextField
          label="Password"
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: '20px' }}
          onClick={submit}
        >
          Sign In
        </Button>
        <Typography
          variant="body2"
          sx={{
            margin: '10px 0',
            lineHeight: '66.8px',
            width: '250px',
            color: 'white',
          }}
        >
          Don't have an account?{' '}
          <Button
            variant="text"
            color="inherit"
            sx={{ textTransform: 'none', padding: 0, fontWeight: 'bold' }}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </Typography>
        <Button
          onClick={() => googleLogin()}
          variant="contained"
          fullWidth
          style={{
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'none',
          }}
          startIcon={
            <img
              src={google}
              alt="logo"
              style={{ height: '24px', width: '24px' }}
            />
          }
        >
          <Typography variant="body1">Sign In With Google</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignIn;
